import request from '@/utils/request'

// 新增会员后台积分积换
export function saveScoreExchange(data) {
    return request({
        url: '/api/system/vip/bill/scoreExchange/save',
        method: 'post',
        data
    })
}
// 会员后台积分积换分页列表(历史单据)
export function scoreExchangeList(params) {
    return request({
        url: '/api/system/vip/bill/scoreExchange/list',
        method: 'get',
        params
    })
}
// 获取会员后台积分积换详细信息
export function getScoreExchangeDetail(billId) {
    return request({
        url: `/api/system/vip/bill/scoreExchange/getDetail?billId=${billId}`,
    })
}
// 删除
export function billPosRetailRemove (data) {
    return request({
        url: '/api/system/shop/bill/posRetail/remove',
        method: 'delete',
        data
    })
}
// 更新审核单据
export function updateScoreExchangeStatus(data) {
    return request({
        url: '/api/system/vip/bill/scoreExchange/updateBillStatus',
        method: 'post',
        data
    })
}


// // 修改会员后台积分积换
export function updateScoreExchange(data) {
    return request({
        url: '/api/system/vip/bill/scoreExchange/update',
        method: 'put',
        data
    })
}






