//分销员会员提成报表
import request from '@/utils/request'

//列表
export function distributionVipCommission (query) {
    return request({
      url: '/api/system/vip/distribution/reportForms/distributionVipCommission',
      method: 'get',
      params: query
    })
  }