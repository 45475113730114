//分销员结算 
import request from '@/utils/request'

//列表
export function distributionSettlement (query) {
    return request({
      url: '/api/system/vip/distribution/reportForms/distributionSettlement',
      method: 'get',
      params: query
    })
  }