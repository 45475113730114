import request from '@/utils/request'

//分销员提成方案   分页列表
export function commissionListAPI (params) {
    return request({
      url: '/api/system/vip/distribution/commission/list',
      method: 'get',
      params
    })
  }
  // 分销员提成方案  新增
  export function commissionAddAPI (data) {
    return request({
      url: '/api/system/vip/distribution/commission/save',
      method: 'post',
      data
    })
  }
  //分销员提成方案  详情
  export function commissionDetailAPI (billId) {
    return request({
      url: '/api/system/vip/distribution/commission/getDetail?billId=' + billId,
      method: 'get'
    })
  }
  // 分销员提成方案   修改
  export function commissionUpdateAPI (data) {
    return request({
      url: '/api/system/vip/distribution/commission/update',
      method: 'put',
      data
    })
  }


//更新分销提成方案状态（删除）
export function updateBillStatus(billIds, billStatus, url, billType) {
  return request({
    url,
    method: 'post',
    data: {
      billIds,
      billStatus,
      billType
    }
  })
}
  
// 复制分销方案
export function copyDistribution(billIds) {
  return request({
    url: '/api/system/vip/distribution/commission/copy',
    method:'post',
    data:{
      billIds
    }
  })
}


// 是否启用/停用分销方案
export function isStoDistribution(data) {
  return request({
    url: '/api/system/vip/distribution/commission/isStop',
    method:'put',
    data
  })
}