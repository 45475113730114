import request from '@/utils/request'

// 会员充值查询
export function vipFillQuery(params) {
    return request({
        url: '/api/system/vip/business/bill/vipFillDetails',
        method: 'get',
        params
    })
}

// 会员充值查询汇总
export function vipFillQuerySummary(params) {
    return request({
        url: '/api/system/vip/business/bill/vipFillDetailsSummary',
        method: 'get',
        params
    })
}

// 会员换卡查询
export function vipCardChangeQuery(params) {
    return request({
        url: '/api/system/vip/business/bill/vipCardChangeDetails',
        method: 'get',
        params
    })
}

// 会员积分调整查询
export function vipScoreAdjustQuery(params) {
    return request({
        url: '/api/system/vip/business/bill/vipScoreAdjustDetails',
        method: 'get',
        params
    })
}

// 会员积分调整查询汇总
export function vipScoreAdjustQuerySummary(params) {
    return request({
        url: '/api/system/vip/business/bill/vipScoreAdjustDetailsSummary',
        method: 'get',
        params
    })
}

// 会员积分兑换查询
export function vipScoreExchangeQuery(params) {
    return request({
        url: '/api/system/vip/business/bill/vipScoreExchangeDetails',
        method: 'get',
        params
    })
}

// 会员积分兑换查询汇总
export function vipScoreExchangeQuerySummary(params) {
    return request({
        url: '/api/system/vip/business/bill/vipScoreExchangeDetailsSummary',
        method: 'get',
        params
    })
}

// 会员状态调整查询
export function vipStateChangeQuery(params) {
    return request({
        url: '/api/system/vip/business/bill/vipStateChangeDetails',
        method: 'get',
        params
    })
}

// 会员级别调整查询
export function vipLevelChangeQuery(params) {
    return request({
        url: '/api/system/vip/business/bill/vipLevelChangeDetails',
        method: 'get',
        params
    })
}

