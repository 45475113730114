import request from '@/utils/request'

//管家查询
export function butlerListApi(params) {
  return request({
    url: '/api/system/vip/base/birthday/butler/list',
    method: 'get',
    params
  })
}
