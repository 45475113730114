// 子会员查询
import request from '@/utils/request'

// 列表
export function distributionSonVipSummary (query) {
  return request({
    url: '/api/system/vip/distribution/reportForms/distributionSonVipSummary',
    method: 'get',
    params: query
  })
}